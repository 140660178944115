import { Elm } from "./Main.elm";

const persistedData = JSON.parse(localStorage.getItem("data"))

const app = Elm.Main.init({
  node: document.getElementById("app"),
  flags: persistedData
})

app.ports.persistData.subscribe(s => localStorage.setItem("data", s))
